import { createRouter, createWebHashHistory } from 'vue-router'
import { trackRouter } from 'vue-gtag-next'

import Survey from '../views/Survey.vue'

const routes = [
  {
    path: '/',
    name: 'generic-survey',
    component: Survey,
    redirect: { path: '/surveys/general' }
  },
  {
    path: '/surveys/:surveySlug',
    name: 'specific-survey',
    component: Survey,
    props: true
  },
  {
    path: '/surveys/:surveySlug/completed',
    name: 'completed-survey',
    component: () =>
      import(/* webpackChunkName: "completion" */ '@/views/Completion.vue'),
    props: true
  },
  {
    path: '/admin-results',
    name: 'admin-results',
    component: () =>
      import(/* webpackChunkName: "adminResults" */ '@/views/AdminResults.vue'),
    props: false
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

trackRouter(router)

export default router
