function paddedDayOfMonth(d) {
  return (d.getDate() < 10 ? '0' : '') + d.getDate()
}

function paddedMonth(d) {
  return ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1)
}

export default {
  async loadSession(uuid) {
    const opts = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }

    let response = await fetch('/api/surveys/' + uuid + '/', opts)

    if (response.status == 404) {
      return {
        hasTakenSurvey: false
      }
    }

    return {
      hasTakenSurvey: true
    }
  },
  async getStats(start, end, location = null, ageGroup = null, gender = null) {
    const opts = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    }

    const startDateString = `${start.getFullYear()}${paddedMonth(
      start
    )}${paddedDayOfMonth(start)}`
    const endDateString = `${end.getFullYear()}${paddedMonth(
      end
    )}${paddedDayOfMonth(end)}`

    let url = `/api/surveys/stats?start=${startDateString}&end=${endDateString}`

    if (location) {
      url += `&location=${location}`
    }

    if (ageGroup) {
      url += `&ageGroup=${ageGroup}`
    }

    if (gender) {
      url += `&gender=${gender}`
    }

    let response = await fetch(url, opts)
    return response.json()
  },
  async postSurvey(uuid, data) {
    const opts = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        uuid,
        grouping_slug: data.surveySlug,
        first_name: data.firstName,
        last_name: data.lastName,
        email: data.emailAddress,
        age_group: data.ageGroup,
        location: data.familyLocation.key,
        gender: data.gender,
        parts: data.parts
      })
    }

    let response

    response = await fetch('/api/surveys/' + uuid + '/', opts)

    if (response.status == 404 || response.status == 403) {
      opts.method = 'POST'
      response = await fetch('/api/surveys/', opts)
    }

    if (data.emailAddress != null) {
      const sendOpts = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          uuid: localStorage.getItem('uuid'),
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.emailAddress,
          location: data.familyLocation,
          parts: data.parts
        })
      }

      fetch(
        '/api/surveys/' + localStorage.getItem('uuid') + '/confirm/',
        sendOpts
      ).catch((e) => {
        console.error(e)
      })
    }

    return response.json()
  }
}
