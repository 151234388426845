<template>
  <form class="survey-form">
    <h2 class="p-text-center survey-caption">
      {{ $t('surveyHeaderQuoteLine1') }}
    </h2>
    <h2 class="p-text-center survey-caption">
      {{ $t('surveyHeaderQuoteLine2') }}
    </h2>
    <h2 class="p-text-center survey-caption">
      {{ $t('surveyHeaderQuoteLine3') }}
    </h2>
    <br />
    <br />
    <div class="separator p-mb-5">
      <span class="tag"> Your Info </span>
    </div>
    <!-- <div class="p-fluid p-formgrid p-grid p-py-3">
      <div class="p-field p-col">
        <span class="p-float-label">
          <InputText id="first-name" type="text" v-model="person.firstName" />
          <label for="first-name">{{ $t('firstName') }}</label>
        </span>
      </div>
      <div class="p-field p-col">
        <span class="p-float-label">
          <InputText id="last-name" type="text" v-model="person.lastName" />
          <label for="last-name">{{ $t('lastName') }}</label>
        </span>
      </div>
    </div> -->
    <div class="p-fluid p-formgrid p-grid p-pb-3">
      <div class="p-field p-col">
        <span class="p-float-label">
          <InputText
            id="email-address"
            type="text"
            v-model="person.emailAddress"
            aria-describedby="email-address-help"
          />
          <label for="email-address">{{ $t('emailAddress') }}</label>
        </span>
        <small id="email-address-help" class="p-pl-2" style="color: #6c757d">
          Enter your e-mail if you would like to receive a copy of your survey
          results.
        </small>
      </div>
    </div>
    <div class="p-grid p-fluid p-pb-3">
      <div class="p-col-12 p-xl-3 p-lg-4 p-md-6">
        <span class="p-float-label">
          <Dropdown
            id="age-group"
            v-model="person.ageGroup"
            :options="ageGroups"
            optionLabel="name"
            optionValue="key"
            placeholder=""
            v-on:change="onAgeGroupChanged"
          />
          <label for="age-group">Age Group</label>
        </span>
      </div>
      <div class="p-col-12 p-xl-3 p-lg-4 p-md-6">
        <span class="p-float-label">
          <Dropdown
            id="gender"
            v-model="person.gender"
            :options="genders"
            optionLabel="name"
            optionValue="key"
            placeholder=""
            v-on:change="onGenderChanged"
          />
          <label for="age-group">Gender</label>
        </span>
      </div>
    </div>
    <div class="p-pb-3">
      <div
        v-for="location of familyLocations"
        :key="location.key"
        class="p-field-radiobutton"
      >
        <RadioButton
          :id="location.key"
          name="familyLocation"
          :value="location"
          v-model="person.familyLocation"
          :disabled="location.key === 'R'"
          v-on:change="onLocationChanged(location)"
        />
        <label :for="location.key">{{ location.name }}</label>
      </div>
    </div>
    <div class="separator p-mb-5">
      <span class="tag"> Survey </span>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col-12 p-lg-6 p-pb-3">
        <survey-card background="#2196f3" :data="data[0]" />
      </div>
      <div class="p-col-12 p-lg-6">
        <survey-card background="#f44336" :data="data[1]" />
      </div>
    </div>
    <div class="p-fluid p-grid">
      <div class="p-col-12 p-lg-6 p-pb-3">
        <survey-card background="#6c757d" :data="data[2]" />
      </div>
      <div class="p-col-12 p-lg-6 p-pb-3">
        <survey-card background="#6c757d" :data="data[3]" />
      </div>
    </div>
    <div class="p-d-flex p-jc-center p-mt-6">
      <Button :label="$t('submit')" @click="submit" :disabled="!canSubmit" />
    </div>
  </form>
</template>

<script>
import { reactive, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { useGtag } from 'vue-gtag-next'

import SurveyCard from './SurveyCard'

export default {
  name: 'SurveyForm',
  components: {
    SurveyCard
  },
  props: {
    surveySlug: String,
    parts: Array
  },
  setup(props) {
    const store = useStore()
    const router = useRouter()
    const i18n = useI18n()
    const { event } = useGtag()

    const familyLocations = [
      {
        key: 'L',
        name: i18n.t('localFamily')
      },
      {
        key: 'N',
        name: i18n.t('nonLocalFamily')
      },
      {
        key: 'O',
        name: 'Other [not part of TRBC family]'
      }
    ]

    const ageGroups = [
      {
        key: '0-12',
        name: '12 & under'
      },
      {
        key: '13-17',
        name: '13-17'
      },
      {
        key: '18-25',
        name: '18-25'
      },
      {
        key: '26-35',
        name: '26-35'
      },
      {
        key: '36-45',
        name: '36-45'
      },
      {
        key: '46-55',
        name: '46-55'
      },
      {
        key: '56-65',
        name: '56-65'
      },
      {
        key: '65plus',
        name: '65+'
      }
    ]

    const genders = [
      {
        key: 'male',
        name: 'Male'
      },
      {
        key: 'female',
        name: 'Female'
      }
    ]

    const person = reactive({
      // firstName: localStorage.getItem('firstName'),
      // lastName: localStorage.getItem('lastName'),
      emailAddress: localStorage.getItem('email'),
      familyLocation: JSON.parse(localStorage.getItem('familyLocation')),
      ageGroup: JSON.parse(localStorage.getItem('ageGroup')),
      gender: JSON.parse(localStorage.getItem('gender'))
    })

    const data = reactive(JSON.parse(JSON.stringify(props.parts)))

    const canSubmit = computed(() => {
      return person.familyLocation != null
    })

    const onLocationChanged = function (location) {
      store.dispatch('changeLocation', location)
    }

    const onAgeGroupChanged = function () {
      store.dispatch('changeAgeGroup', person.ageGroup)
    }

    const onGenderChanged = function () {
      store.dispatch('changeGender', person.gender)
    }

    const submit = function () {
      event('survey_submit_click', {
        event_category: 'survey',
        event_label: 'click'
      })

      // localStorage.setItem('firstName', person.firstName)
      // localStorage.setItem('lastName', person.lastName)
      localStorage.setItem('email', person.emailAddress)

      store
        .dispatch('submitSurvey', {
          surveySlug: props.surveySlug,
          // firstName: person.firstName,
          // lastName: person.lastName,
          emailAddress: person.emailAddress,
          familyLocation: person.familyLocation,
          ageGroup: person.ageGroup,
          gender: person.gender,
          parts: data
        })
        .then(() => {
          event('survey_submit', {
            event_category: 'survey',
            event_label: 'submission',
            value: 1
          })
          router.push({
            name: 'completed-survey',
            params: {
              surveySlug: props.surveySlug
            }
          })
        })
        .catch((e) => {
          console.error(e)
        })
    }

    return {
      canSubmit,
      familyLocations,
      genders,
      ageGroups,
      person,
      data,
      submit,
      onLocationChanged,
      onAgeGroupChanged,
      onGenderChanged
    }
  }
}
</script>

<style>
.p-card .p-card-body {
  padding: 0px !important;
  margin: 0px;
}

.p-card .p-card-content {
  padding: 0px !important;
  margin: 0px;
}

.survey-caption {
  font-weight: bolder;
  color: rgb(14, 126, 166);
  padding-left: 25%;
  padding-right: 25%;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.survey-caption-sm {
  font-weight: normal;
  font-size: 16px;
  max-width: 75%;
  margin-left: auto;
  margin-right: auto;
  color: rgb(36, 73, 87);
}

@media screen and (max-width: 575px) {
  .survey-caption {
    font-size: 16px;
    padding-left: 2%;
    padding-right: 2%;
  }

  .survey-caption-sm {
    font-size: 16px;
    max-width: 95%;
  }
}

@media screen and (min-width: 576px) {
  .survey-caption {
    font-size: 18px;
    padding-left: 4%;
    padding-right: 4%;
  }

  .survey-caption-sm {
    font-size: 16px;
    max-width: 90%;
  }
}

@media screen and (min-width: 768px) {
  .survey-caption {
    font-size: 20px;
    padding-left: 5%;
    padding-right: 5%;
  }

  .survey-caption-sm {
    font-size: 16px;
    max-width: 85%;
  }
}

@media screen and (min-width: 992px) {
  .survey-caption {
    font-size: 22px;
    padding-left: 8%;
    padding-right: 8%;
  }

  .survey-caption-sm {
    font-size: 16px;
    max-width: 80%;
  }
}

@media screen and (min-width: 1200px) {
  .survey-caption {
    font-size: 24px;
    padding-left: 15%;
    padding-right: 15%;
  }

  .survey-caption-sm {
    font-size: 18px;
  }
}
</style>
