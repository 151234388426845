<template>
  <Card class="p-shadow-4">
    <template #header>
      <div
        :style="
          'border-top-right-radius: 3px; border-top-left-radius: 3px; color: white; background-color: ' +
          background +
          ';'
        "
      >
        <div class="p-grid">
          <div class="p-col-12 p-pb-0">
            <div class="p-px-4 p-py-2 p-text-center">
              <h2 style="text-transform: uppercase">{{ data.title }}</h2>
              <h3>{{ data.subTitle }}</h3>
            </div>
          </div>
          <div class="p-col-12 p-pt-0">
            <div class="p-d-flex p-jc-center">
              <div class="p-px-3">
                <p v-if="!loading" class="p-text-center">
                  {{ $t('totalScore') }}
                </p>
                <div class="p-d-flex p-jc-center">
                  <Knob
                    v-if="!loading"
                    class="p-pr-3 p-m-0 p-p-0"
                    v-model="totalScore"
                    :max="maxScore"
                    valueColor="#689F38"
                    textColor="#ffffff"
                  ></Knob>
                </div>
              </div>
              <div class="p-px-3">
                <p v-if="!loading" class="p-text-center">
                  {{ $t('averageScore') }}
                </p>
                <div class="p-d-flex p-jc-center">
                  <Knob
                    v-if="!loading"
                    class="p-pr-3 p-m-0 p-p-0"
                    v-model="averageScore"
                    :max="data.max"
                    valueColor="#689F38"
                    textColor="#ffffff"
                  ></Knob>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #content>
      <DataTable
        :class="dataTableClass"
        styleClass="p-p-0 p-m-0"
        :value="messages.data"
        dataKey="id"
        :loading="loading"
      >
        <template #empty>{{ $t('noData') }}</template>
        <template #loading>{{ $t('loadingData') }}</template>
        <Column field="text" header="Statement">
          <template #body="slotProps">
            {{ slotProps.data.id + 1 }}. {{ slotProps.data.text }}
          </template>
        </Column>
        <Column
          field="value"
          header="Answer: 5-1 [5 = Strong, 1 = Weak]"
          headerStyle="width: 300px"
        >
          <template #body="slotProps">
            <Slider
              :id="data.title + '-slider-' + slotProps.data.id"
              v-model="messages.data[slotProps.data.id].value"
              :min="data.min"
              :max="data.max"
            />
            <div
              v-if="messages.data[slotProps.data.id]"
              class="p-inputgroup p-mt-4"
            >
              <Button
                icon="pi pi-minus"
                class="p-button-danger disable-dbl-tap-zoom"
                @click="minusScore(slotProps.data.id)"
              />
              <InputNumber
                v-model="messages.data[slotProps.data.id].value"
                inputClass="p-text-center"
                :min="data.min"
                :max="data.max"
              />
              <Button
                icon="pi pi-plus"
                class="p-button-success disable-dbl-tap-zoom"
                @click="plusScore(slotProps.data.id)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
import { reactive, computed } from 'vue'

export default {
  name: 'SurveyCard',
  props: {
    background: String,
    data: Object
  },
  setup(props) {
    const messages = reactive({
      data: props.data.messages
    })

    const plusScore = function (id) {
      if (messages.data[id].value >= props.data.max) {
        messages.data[id].value = props.data.max
      } else {
        messages.data[id].value += 1
      }
    }

    const minusScore = function (id) {
      if (messages.data[id].value <= props.data.min) {
        messages.data[id].value = props.data.min
      } else {
        messages.data[id].value -= 1
      }
    }

    const loading = computed(
      () => messages.data == null || messages.data.length == 0
    )

    const dataTableClass = computed(function () {
      let classStr = 'p-datatable-responsive-demo'

      return classStr
    })

    const totalScore = computed(function () {
      if (messages.data && messages.data.length > 0) {
        const initialValues = {
          totalScore: 0
        }
        return Number(
          messages.data.reduce(({ totalScore }, item) => {
            return {
              totalScore: totalScore + item.value
            }
          }, initialValues).totalScore
        )
      } else {
        return Number(0)
      }
    })

    const averageScore = computed(function () {
      if (messages.data && messages.data.length > 0) {
        return Number((totalScore.value / messages.data.length).toPrecision(1))
      }
      return Number(0.0)
    })

    const maxScore = computed(function () {
      if (messages.data && messages.data.length > 0) {
        return Number(messages.data.length * props.max)
      } else {
        return Number(0)
      }
    })

    const onInputChanged = function (event, id) {
      if (event.value > props.data.max) {
        messages.data[id].value = props.data.max
        console.log(messages.data[id])
      } else if (event.value < props.data.min) {
        messages.data[id].value = props.data.min
      }
    }

    return {
      messages,
      plusScore,
      minusScore,
      loading,
      dataTableClass,
      totalScore,
      averageScore,
      maxScore,
      onInputChanged
    }
  }
}
</script>

<style lang="scss" scoped>
body {
  background-color: black;
}

.disable-dbl-tap-zoom {
  touch-action: manipulation;
}

.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  ::v-deep(.p-datatable) {
    &.p-datatable-responsive-demo {
      .p-datatable-thead > tr > th,
      .p-datatable-tfoot > tr > td {
        display: none !important;
      }

      .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;

        .p-column-title {
          padding: 0.4rem;
          min-width: 30%;
          display: inline-block;
          margin: -0.4em 1em -0.4em -0.4rem;
          font-weight: bold;
        }

        &:last-child {
          border-bottom: 1px solid var(--surface-d);
        }
      }
    }
  }
}
</style>
