import { createStore } from 'vuex'
import SurveyService from '@/services/SurveyService'

export default createStore({
  state: {
    uuid: localStorage.getItem('uuid'),
    hasTakenSurvey: JSON.parse(localStorage.getItem('hasTakenSurvey')) == true,
    // firstName: null,
    // lastName: null,
    email: null,
    location: JSON.parse(localStorage.getItem('familyLocation')),
    ageGroup: JSON.parse(localStorage.getItem('ageGroup')),
    parts: JSON.parse(localStorage.getItem('parts'))
  },
  mutations: {
    setSurveyParts(state, parts) {
      state.parts = parts
      localStorage.setItem('parts', JSON.stringify(parts))
    },
    setSurveyPart(state, { index, value }) {
      state.parts[index] = value
    },
    setHasTakenSurvey(state, value) {
      state.hasTakenSurvey = value
      localStorage.setItem('hasTakenSurvey', JSON.stringify(value))
    },
    // setFirstName(state, value) {
    //   state.firstName = value
    // },
    // setLastName(state, value) {
    //   state.lastName = value
    // },
    setEmailAddress(state, value) {
      state.email = value
    },
    setLocation(state, value) {
      state.location = value
      localStorage.setItem('familyLocation', JSON.stringify(value))
    },
    setAgeGroup(state, value) {
      state.ageGroup = value
      localStorage.setItem('ageGroup', JSON.stringify(value))
    },
    setGender(state, value) {
      if (value != 'male' && value != 'female') {
        state.gender = null
      } else {
        state.gender = value
      }

      console.log(state.gender)

      localStorage.setItem('gender', JSON.stringify(state.gender))
    }
  },
  getters: {
    hasEmailAddress(state) {
      return state.email != null
    }
  },
  actions: {
    loadSession({ state, commit }, uuid) {
      if (uuid != null && state.uuid == null) {
        state.uuid = uuid
      }
      SurveyService.loadSession(state.uuid).then((result) => {
        commit('setHasTakenSurvey', result.hasTakenSurvey)
      })
    },
    resetSurveyStatus({ commit }) {
      commit('setHasTakenSurvey', false)
    },
    fetchSurvey({ commit }) {
      const parts = JSON.parse(localStorage.getItem('parts'))
      commit('setSurveyParts', parts)
    },
    loadSurvey({ commit }, parts) {
      commit('setSurveyParts', parts)
      commit('setHasTakenSurvey', false)
    },
    submitSurvey({ state, commit }, data) {
      // commit('setFirstName', data.firstName)
      // commit('setLastName', data.lastName)
      commit('setEmailAddress', data.emailAddress)
      commit('setSurveyParts', data.parts)
      commit('setLocation', data.familyLocation)
      commit('setHasTakenSurvey', true)
      SurveyService.postSurvey(state.uuid, data)
    },
    changeLocation({ commit }, location) {
      commit('setLocation', location)
    },
    changeAgeGroup({ commit }, ageGroup) {
      commit('setAgeGroup', ageGroup)
    },
    changeGender({ commit }, gender) {
      commit('setGender', gender)
    }
  },
  modules: {}
})
