<template>
  <div>
    <img
      class="center"
      src="https://cdn.trbc.org/wp-content/uploads/trbc-logo-565px.png"
    />
    <router-view />
  </div>
</template>

<style>
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  padding: 0px;
  margin: 0px;
  background: #f5f5f5;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  height: auto;
}
</style>
