import { createI18n } from 'vue-i18n'
import * as en from './locales/en.json'
import * as es from './locales/es.json'

export const locales = {
  en: en,
  es: es
}

export function loadLocaleMessages() {
  const messages = Object.assign(locales)
  return messages
}

const i18n = createI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
})

export default i18n
