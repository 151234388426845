// Vue
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

if (
  localStorage.getItem('uuid') == null ||
  localStorage.getItem('uuid') == 'undefined'
) {
  localStorage.setItem('uuid', uuid())
}

if (
  localStorage.getItem('hasTakenSurvey') == null ||
  localStorage.getItem('hasTakenSurvey') == 'undefined'
) {
  localStorage.setItem('hasTakenSurvey', false)
}

if (
  localStorage.getItem('familyLocation') == null ||
  localStorage.getItem('familyLocation') == 'undefined'
) {
  localStorage.setItem('familyLocation', null)
}

if (
  localStorage.getItem('gender') == null ||
  localStorage.getItem('gender') == 'undefined'
) {
  localStorage.setItem('gender', null)
}

// if (
//   localStorage.getItem('firstName') == null ||
//   localStorage.getItem('firstName') == 'undefined'
// ) {
//   localStorage.setItem('firstName', '')
// }

// if (
//   localStorage.getItem('lastName') == null ||
//   localStorage.getItem('lastName') == 'undefined'
// ) {
//   localStorage.setItem('lastName', '')
// }

if (
  localStorage.getItem('email') == null ||
  localStorage.getItem('email') == 'undefined'
) {
  localStorage.setItem('email', '')
}

import store from './store'

store.dispatch('loadSession', localStorage.getItem('uuid'))

// Local
import { uuid } from '@/helpers'
import i18n from '@/i18n'

// PrimeVue
import PrimeVue from 'primevue/config'
import InputText from 'primevue/inputtext'
import Button from 'primevue/button'
import Toast from 'primevue/toast'
import ToastService from 'primevue/toastservice'
import Card from 'primevue/card'
import Calendar from 'primevue/calendar'
import Divider from 'primevue/divider'
import Panel from 'primevue/panel'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import Slider from 'primevue/slider'
import InputNumber from 'primevue/inputnumber'
import Knob from 'primevue/knob'
import Accordion from 'primevue/accordion'
import AccordionTab from 'primevue/accordiontab'
import Badge from 'primevue/badge'
import Password from 'primevue/password'
import RadioButton from 'primevue/radiobutton'
import Dropdown from 'primevue/dropdown'
import Toolbar from 'primevue/toolbar'
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import 'primeflex/primeflex.css'

// Google Analytics
import VueGtag from 'vue-gtag-next'

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(PrimeVue, { ripple: true })
  .use(ToastService)
  .use(VueGtag, {
    property: {
      id: 'G-YWCF7Y2QKY'
    }
  })
  .component('InputText', InputText)
  .component('Button', Button)
  .component('Toast', Toast)
  .component('Card', Card)
  .component('Calendar', Calendar)
  .component('Divider', Divider)
  .component('Panel', Panel)
  .component('DataTable', DataTable)
  .component('Column', Column)
  .component('Slider', Slider)
  .component('InputNumber', InputNumber)
  .component('Knob', Knob)
  .component('Accordion', Accordion)
  .component('AccordionTab', AccordionTab)
  .component('Badge', Badge)
  .component('Password', Password)
  .component('RadioButton', RadioButton)
  .component('Dropdown', Dropdown)
  .component('Toolbar', Toolbar)
  .mount('#app')
